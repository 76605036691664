import Link from 'next/link';
export default function Nav() {
  return (
    <ul>
      <li className='menu-item'>
        <Link href='/#about'>
          <a title='About'>About</a>
        </Link>
      </li>

      <li className='menu-item'>
        &gt;{' '}
        <Link href='/#whats-inside'>
          <a title="What's Inside">What&apos;s Inside</a>
        </Link>
      </li>

      <li className='menu-item'>
        &gt;{' '}
        <Link href='/#eligibility'>
          <a title='Eligibility'>Eligibility</a>
        </Link>
      </li>
    </ul>
  );
}
